import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const PhotoList = () => {
    const [photos, setPhotos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [photosPerPage] = useState(15); // Change this value based on how many photos you want per page
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://pwa.gravos.fr/api/get_photos_data');
                if (response.data.status === 'success') {
                    setPhotos(response.data.data);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching photos:', error);
                setLoading(false);
            }
        };

        fetchPhotos();
    }, []);

    // Get current photos
    const indexOfLastPhoto = currentPage * photosPerPage;
    const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
    const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="container mt-5">
            <h2 className="text-center">Photo Gallery</h2>

            {loading ? (
                <div className="text-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <table className="table table-bordered table-hover mt-3">
                        <thead className="table-dark">
                            <tr>
                                <th>ID</th>
                                <th>Location</th>
                                <th>Comment</th>
                                <th>Photo</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPhotos.map((photo) => (
                                <tr key={photo.ID}>
                                    <td>{photo.ID}</td>
                                    <td>{photo.Location}</td>
                                    <td>{photo.Comment}</td>
                                    <td>{photo.Photo}</td>
                                    <td>{photo.Latitude}</td>
                                    <td>{photo.Longitude}</td>
                                    <td>{new Date(photo.CrDtTm).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        photosPerPage={photosPerPage}
                        totalPhotos={photos.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                </>
            )}
        </div>
    );
};

// Pagination component
const Pagination = ({ photosPerPage, totalPhotos, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalPhotos / photosPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav>
            <ul className="pagination justify-content-center">
                {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => paginate(number)} className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default PhotoList;
