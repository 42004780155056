import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const CameraCapture = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [photo, setPhoto] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallPrompt, setShowInstallPrompt] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };

        checkMobile();
        startCamera();

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    console.error('Error fetching location: ', error);
                }
            );
        }

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setShowInstallPrompt(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        window.addEventListener('appinstalled', () => {
            setShowInstallPrompt(false);
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const startCamera = async () => {
        try {
            const constraints = {
                video: {
                    facingMode: { ideal: 'environment' }, // Try to use the back camera if available
                },
            };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                videoRef.current.onloadedmetadata = () => {
                    videoRef.current.play().catch((error) => console.error('Error playing video:', error));
                };
            }
        } catch (err) {
            console.error('Error accessing the camera: ', err);
        }
    };

    const capturePhoto = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
    
        // Set canvas dimensions to match the video feed dimensions exactly
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
    
        // Draw the video frame to the canvas as it is
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
    
        // Get the data URL of the image in its original resolution and aspect ratio
        const photoData = canvas.toDataURL('image/png');
        setPhoto(photoData);
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!photo) {
            alert('Please capture a photo.');
            return;
        }

        // Set loading to true when upload starts
        setIsLoading(true);

        const byteString = atob(photo.split(',')[1]);
        const mimeString = photo.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: mimeString });
        const now = new Date();
        const formattedDateTime = `${now.getDate().toString().padStart(2, '0')}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getFullYear()}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
        const fileName = `img-${formattedDateTime}.png`;
        const file = new File([blob], fileName, { type: mimeString });

        const formData = new FormData();
        formData.append('photo', file);
        formData.append('location', location);
        formData.append('comment', description);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);

        try {
            await axios.post('https://pwa.gravos.fr/api/upload_image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Photo uploaded successfully!');

            setPhoto(null);
            setLocation('');
            setDescription('');
            startCamera();
        } catch (error) {
            console.error('Error uploading photo:', error.response?.data || error.message);
            alert('Failed to upload photo.');
        } finally {
            // Set loading to false once upload is complete
            setIsLoading(false);
        }
    };

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
                setShowInstallPrompt(false);
            });
        }
    };

    return (
        <div className="container mt-5" style={{ backgroundColor: '#51545D' }}>
            {isLoading && (
                <div className="loading-overlay">
                    <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {showInstallPrompt && (
                <div style={{
                    position: 'fixed',
                    top: '10px',
                    left: '10px',
                    textAlign: 'center',
                    color: 'yellow',
                    zIndex: 1000 // Ensure it stays above other elements
                }}>
                    <p>Mettre l'icone</p>
                    <img
                        src={`${process.env.PUBLIC_URL}/install_app.png`}
                        alt="Install App"
                        style={{
                            width: '40px',
                            height: 'auto',
                            cursor: 'pointer',
                        }}
                        onClick={handleInstallClick}
                    />
                </div>
            )}

            <div style={{
                position: 'fixed',
                top: '10px',
                right: '10px',
                textAlign: 'center',
                color: 'yellow',
                zIndex: 1000 // Ensure it stays above other elements
            }}>
                <p>Voir le site</p>
                <img
                    src={`${process.env.PUBLIC_URL}/go_to_site.png`}
                    alt="Go to Site"
                    style={{
                        width: '72px',
                        height: 'auto',
                        cursor: 'pointer',
                    }}
                    onClick={() => window.location.href = 'https://gravos.fr/'}
                />
            </div>
            <h2 className="text-center"><img src={`${process.env.PUBLIC_URL}/gravos_logo_page.png`} alt="Logo" className="img-fluid" style={{ maxWidth: '200px' }} /></h2>
            <div className="text-center mt-4">
                {photo ? (
                    <img src={photo} alt="Captured" className="img-fluid" style={{ maxWidth: '300px' }} />
                ) : (
                    <>
                        <video ref={videoRef} width="300" autoPlay playsInline className="mb-3"></video>
                        <canvas ref={canvasRef} width="300" height="200" style={{ display: 'none' }}></canvas>
                        <div className="mt-3">
                            <button className="btn btn-custom" onClick={capturePhoto}>
                                Capturer
                            </button>
                        </div>
                    </>
                )}
            </div>

            <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-3">
                    <label className="form-label" style={{ color: 'black' }}>Lieu:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label style={{ color: 'black' }}>Description:</label>
                    <textarea
                        className="form-control mt-2"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                        rows="3"
                    />
                </div>
                <button type="submit" className="btn btn-custom w-100 mb-4 mt-2">
                    Envoyer
                </button>
            </form>
        </div>
    );
};

export default CameraCapture;
