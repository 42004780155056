import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CameraCapture from './components/uploadPhoto';
import PhotoList from './components/tableData';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    {/* Default route shows the UploadPhoto component */}
                    <Route path="/" element={<CameraCapture />} />
                    {/* Route for the GetPhotosData component */}
                    <Route path="/photos_data" element={<PhotoList />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
